import enterpriseApi from '@/api/enterprise.api';
import fleetApi from '@/api/fleet.api';
import reportStorageApi from '@/api/report-storage.api';
import thingApi from '@/api/thing.api';
import i18n from '@/i18n';
import { TYPE_KEY } from '@colven/common-domain-lib/lib';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { ABLE_TRUCKS_HEADERS, OUT_OF_SERVICE_TRUCKS_HEADERS, SHIFTS_KEYS, TOTALS_STYLE, WITHOUT_ACTIVITY_TRUCKS_HEADERS } from './constants';

class ShiftSummarySerivce {

    async generateReportStored(reportStoragedId) {
        const reportData = await reportStorageApi.getReport(reportStoragedId);
        const selectedEnterprise = JSON.parse(localStorage.getItem('enterpriseId'))
        const { data: categoriesData } = await enterpriseApi.getCategoriesPaginated(null, null, selectedEnterprise)
        const fleets = this.getFleetMap(await fleetApi.getAll());
        const categories = this.getCategoryMap(categoriesData);
        const things = this.getThingsMap(await thingApi.getThingByTypeKey(TYPE_KEY.TRUCK));
        this.generateExcelReport(reportData, categories, fleets, things);
    }
    getFleetMap(fleets) {
        const fleetMap = new Map();
        if (fleets && fleets.length > 0) {
            for (const fleet of fleets) {
                fleetMap.set(fleet._id, fleet.name);
            }
        }
        return fleetMap;
    }
    getCategoryMap({ data: categories }) {
        const categoryMap = new Map();
        if (categories && categories.length > 0) {
            for (const category of categories) {
                categoryMap.set(category._id, category.name);
            }
        }
        return categoryMap;
    }
    getThingsMap({ data: things }) {
        const thingsMap = new Map();
        if (things && things.length > 0) {
            for (const thing of things) {
                thingsMap.set(thing._id, thing.name);
            }
        }
        return thingsMap;
    }
    generateExcelReport(reportData, categoriesMap, fleetMap, thingMap) {
        const workBook = new ExcelJS.Workbook();
        let timezone = localStorage.getItem('timezone');
        const fileName = `${moment.unix(reportData.data.filters.dateSelected).utcOffset(timezone).format('DD_MM')} - ${i18n.t(SHIFTS_KEYS[reportData.data.filters.selectedShift])}`
        const { ableTrucksData, outOfServiceData, withOutActivity, totals } = reportData.data.report;
        const ableGroupData = this.getAbleGroupsByCategory(ableTrucksData);
        for (const groupData of ableGroupData) {
            const sheetName = categoriesMap.get(groupData.categoryId) || i18n.t('shiftSummaryReport.table.noCategory');
            this.createSheet(sheetName, groupData.data, JSON.parse(JSON.stringify(ABLE_TRUCKS_HEADERS)), categoriesMap, fleetMap, thingMap, workBook, totals[groupData.categoryId]);
        }
        this.createSheet("Fuera de servicio", outOfServiceData, JSON.parse(JSON.stringify(OUT_OF_SERVICE_TRUCKS_HEADERS)), categoriesMap, fleetMap, thingMap, workBook);
        this.createSheet("Sin Movimiento", withOutActivity, JSON.parse(JSON.stringify(WITHOUT_ACTIVITY_TRUCKS_HEADERS)), categoriesMap, fleetMap, thingMap, workBook);
        workBook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${i18n.t('exportFilename.shift.summary')}_${fileName}.xlsx`);
        }).catch(err => {
            console.error('Error al guardar el archivo:', err);
        });
    }

    createSheet(sheetName, data, headers, categoryMap, fleetMap, thingMap, workBook, totals) {
        // const headerAndTotalStyle = this.getHeaderAndTotalExcelStyle();
        // const contentBorderStyle = this.getContentBorderExcelStyle();
        const worksheet = workBook.addWorksheet(sheetName);
        const headersText = headers.map(header => `${i18n.t(`shiftSummaryReport.headers.${header.key}`)}`);
        const headerRow = worksheet.addRow(headersText);
        headerRow.eachCell((cell, index) => {
            const headerStyle = headers[index - 1].headerStyle;
            if (headerStyle) {
                for (const style in headerStyle) {
                    cell[style] = headerStyle[style];
                }
            }
        });
        for (const truckData of data) {
            const row = [];
            for (const header of headers) {
                let value = truckData[header.field];
                if (header.type === "category") {
                    value = categoryMap.get(truckData[header.field]) || i18n.t('shiftSummaryReport.table.noCategory');
                } else if (header.type === "fleet") {
                    value = fleetMap.get(truckData[header.field]) || i18n.t('shiftSummaryReport.table.noFleet');
                } else if (header.type === "thing") {
                    value = thingMap.get(truckData[header.field]);
                } else if (header.type === "timestampFormat") {
                    value = this.getDateFormat(truckData[header.field]);
                }
                row.push(value);
            }
            const rowState = worksheet.addRow(row);
            // Aplicar estilos a la fila
            rowState.eachCell((cell, index) => {
                const rowStyle = headers[index - 1].rowStyle;
                if (rowStyle) {
                    for (const style in rowStyle) {
                        cell[style] = rowStyle[style];
                    }
                }
            });
        }

        worksheet.addRow();
        if (totals) {
            for (const fleetKey of Object.keys(totals.fleets)) {
                const fleet = totals.fleets[fleetKey];
                if (fleet.tripsCount) {
                    const rowState = worksheet.addRow([
                        `Total`,
                        `${fleetMap.get(fleet.fleetId) || i18n.t('shiftSummaryReport.table.noFleet')} `,
                        fleet.trucks,
                        fleet.tripsCount,
                        this.getDateFormat(fleet.outOfServiceTime)
                    ]);
                    rowState.eachCell((cell, index) => {
                        cell.font = TOTALS_STYLE.font;
                        cell.fill = TOTALS_STYLE.fill;
                        if (index > 2) {
                            cell.alignment = TOTALS_STYLE.alignmentRight;
                        } else {
                            cell.alignment = TOTALS_STYLE.alignmentLeft;
                        }
                    });
                }

            }
            const rowState = worksheet.addRow([
                `Total ${categoryMap.get(totals.categoryId) || i18n.t('shiftSummaryReport.table.noCategory')}`,
                '',
                totals.total.trucks,
                totals.total.tripsCount,
                this.getDateFormat(totals.total.outOfServiceTime)
            ]);
            rowState.eachCell((cell, index) => {
                cell.font = TOTALS_STYLE.font;
                cell.fill = TOTALS_STYLE.fill;
                if (index > 2) {
                    cell.alignment = TOTALS_STYLE.alignmentRight;
                } else {
                    cell.alignment = TOTALS_STYLE.alignmentLeft;
                }
            });
        }
        // Ajustar el ancho de las columnas
        worksheet.columns.forEach((column) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const cellValue = cell.value ? cell.value.toString() : '';
                maxLength = Math.max(maxLength, cellValue.length);
            });
            column.width = maxLength + 2; // Ajustar ancho con un pequeño margen
        });
    }

    getDateFormat(seconds) {
        const days = Math.floor(seconds / (3600 * 24));
        const secondDay = seconds % 86400;
        return `${days || ''} ${moment(secondDay * 1000).utc().format("HH:mm:ss")}`
    }

    getAbleGroupsByCategory(ableTruckData) {
        const result = {};
        if (ableTruckData && ableTruckData.length > 0) {
            for (const truckData of ableTruckData) {
                let categoryData = result[truckData.categoryId]
                if (!categoryData) {
                    categoryData = { categoryId: truckData.categoryId, data: [] };
                    result[truckData.categoryId] = categoryData;
                }
                categoryData.data.push(truckData);
            }
        }
        return Object.values(result);
    }

    // getHeaderAndTotalExcelStyle() {
    //     return {
    //         font: { bold: true },
    //         border: {
    //             top: { style: 'medium' },
    //             bottom: { style: 'medium' },
    //             left: { style: 'medium' },
    //             right: { style: 'medium' }
    //         },
    //         fill: {
    //             type: 'pattern',
    //             pattern: 'solid',
    //             fgColor: { argb: 'ebebeb' }
    //         }
    //     };
    // }

    // getContentBorderExcelStyle() {
    //     return {
    //         border: {
    //             top: { style: 'thin' },
    //             bottom: { style: 'thin' },
    //             left: { style: 'thin' },
    //             right: { style: 'thin' }
    //         }
    //     }
    // }
}
export default new ShiftSummarySerivce();